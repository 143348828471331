module.exports = {
  // Name of your site. Can be the name of a brand, or your personal name.
  title: 'Sarah Dippold Design',
  // What's the purpose of this website? What can we expect from it?
  description: `Some portfolio photos and links to latest Instagram posts`,
  // Your legal name.
  legalName: 'Sarah Dippold Design',
  // URL to this website. If you bought `www.domain.com`, then it will be `www.domain.com`.
  url: 'https://sarahdippold.com',
  // Favicon that will display on browsers.
  icon: 'static/images/favicon.png',
  logo: 'static/images/logo.png',
  // Who made this website?
  author: 'Sarah Dippold Design',
  // What's the website of the author?
  authorUrl: 'https://sarahdippold.com',
  // Your social media presence, in the form of usernames.
  social: {
    // tells the Instagram API the username to get posts from. 
    // If the url is `https://www.instagram.com/natgeotravel`, your username is `natgeotravel`.
    instagram: 'sarahdippolddesign',
    // Optional
    // twitter: '@SarahDippold',
    houzz: 'https://www.houzz.com/professionals/interior-designers-and-decorators/sarah-dippold-design-pfvwus-pf~1828864401?',
  },
  socialLinks: {
    // Complete URL verison of `social.instagram`.
    instagram: 'https://www.instagram.com/sarahdippolddesign',
    // Optional
    // twitter: 'https://twitter.com/SarahDippold',
  },
  // If applicable, your Google Analytics crawl ID.
  googleAnalyticsId: 'UA-XXXXXXXX-X',
  // Colours that represent your website.
  themeColor: '#000',
  backgroundColor: '#fff',
  // No more information than City + Country due to privacy concerns.
  address: {
    city: 'Winnetka',
    country: 'USA',
  },
  // Basic contact details.
  contact: {
    // Email address to contact you.
    email: 'sarah@sarahdippold.com',
  },
  // When was this website created?
  foundingDate: '2020',
};