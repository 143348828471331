import {
  ALIGN,
  HeaderNavigation,
  StyledNavigationItem,
  StyledNavigationList,
} from 'baseui/header-navigation';
import * as React from 'react';
import Link from 'common/link';
import SocialLink from 'common/socialLink';
import siteLogo from 'static/images/SarahDippoldDesign-logo.svg';

import { Icon } from "@iconify/react";
import houzz from "@iconify/icons-fa-brands/houzz";
import facebook from "@iconify/icons-fa-brands/facebook";
import instagram from "@iconify/icons-fa-brands/instagram";
import phone from "@iconify/icons-fa-solid/phone-alt";
import directions from "@iconify/icons-fa-solid/building";
import email from "@iconify/icons-fa-solid/envelope";

const Header = ({ siteTitle }) => (
  <HeaderNavigation
    overrides={{
      Root: {
        style: {
          justifyContent: 'space-between',
          display: 'block',
        },
      },
    }}
  >
    <StyledNavigationList $align={ALIGN.center}>
      <StyledNavigationItem>
        <Link href="/">
          <img src={siteLogo} alt={siteTitle} style={{ height: 93.77, width: 500 }} />
        </Link>
      </StyledNavigationItem>
    </StyledNavigationList>

    <StyledNavigationList $align={ALIGN.center}>
      <StyledNavigationItem>
        <Link href="/projects/">PROJECTS</Link>
      </StyledNavigationItem>
      <StyledNavigationItem>
        <Link href="/press/">PRESS</Link>
      </StyledNavigationItem>
      <StyledNavigationItem>
        <Link href="/about/">ABOUT</Link>
      </StyledNavigationItem>
      <StyledNavigationItem>
        <Link href="mailto:sarah@sarahdippold.com"><Icon icon={email} alt="email" /></Link>
      </StyledNavigationItem>
      <StyledNavigationItem>
        <Link href="tel:+18478676446"><Icon icon={phone} alt="phone" /></Link>
      </StyledNavigationItem>
      <StyledNavigationItem>
        <Link href="https://goo.gl/maps/WsjtKYNfmXdRLF688"><Icon icon={directions} alt="map" /></Link>
      </StyledNavigationItem>
    </StyledNavigationList>

    <StyledNavigationList $align={ALIGN.center}>
      Follow on
      <StyledNavigationItem>
        <SocialLink href="https://www.chairish.com/shop/sarah-dippold-design">
          <img src="https://res.cloudinary.com/altgene/image/upload/w_17,c_thumb,r_max/e_grayscale/v1598655801/sarahdippold.com/site/chairish_nfoouv.png" load="lazy" />
        </SocialLink>
      </StyledNavigationItem>
      <StyledNavigationItem>
        <SocialLink href="https://www.facebook.com/sarahdippolddesign"><Icon icon={facebook} alt="Facebook" /></SocialLink>
      </StyledNavigationItem>
      <StyledNavigationItem>
        <SocialLink href="https://www.instagram.com/sarahdippolddesign"><Icon icon={instagram} /></SocialLink>
      </StyledNavigationItem>

      <StyledNavigationItem>
        <Link href="https://www.houzz.com/professionals/interior-designers-and-decorators/sarah-dippold-design-pfvwus-pf~1828864401?"><Icon icon={houzz} /></Link>
      </StyledNavigationItem>
    </StyledNavigationList>

  </HeaderNavigation>
);

export default Header;
