import { Paragraph1 } from 'baseui/typography';
import { Link } from "gatsby";
import { legalName } from 'data/config';

import * as React from 'react';

const Footer = () => {
  return (
    <footer>
      <Paragraph1
        overrides={{
          Block: {
            style: {
              textAlign: 'center',
            },
          },
        }}
      >
        Copyright © 2016-{new Date().getFullYear()}{' '}<Link to="#">{legalName}</Link>
      </Paragraph1>
    </footer>
  );
};

export default Footer;
